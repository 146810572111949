













import Vue from "vue";
import { Component, Prop, Model, Emit } from "vue-property-decorator";
import {} from "./store";

@Component({
  data() {
    return {};
  },
  computed: {},
  
})
export default class TenantsPageIndex extends Vue 
{
	CreateTN() : void
	{
		this.$router.push({ name: 'tenantcreate'});
	}

	EditTN() : void
	{
		const id = "aa47d7bf-d12c-40fa-9e09-b4093ce1e879";
		this.$router.push({ name: 'tenantedit', params: { id }});
	}
}
