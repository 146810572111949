import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators';
import store from '@/infrastructure/store';
import pgaDiContainer from '@/App.container';
import IIdentityService, { TenantModel, CreateTenantCommand, EditTenantCommand } from '@/services/iIdentityService';
import IdentityService from '@/services/IdentityService';
import { SharedModule } from '@/feature/Shared/store';

export interface TenantsState
{
    TenantCreate: TenantCreateModel;
    TenantEdit: TenantEditModel;
}

export interface TenantCreateModel
{
    CreateTenantCommand: CreateTenantCommand;
    Errors: Map<string, Array<string>>;
}

export interface TenantEditModel
{
    EditTenantCommand: EditTenantCommand;
    Errors: Map<string, Array<string>>;
}

@Module({ namespaced: true, dynamic: true, store, name: 'TenantModule', })
class TenantsStore extends VuexModule implements TenantsState
{
    _identityService: IIdentityService = pgaDiContainer.get<IIdentityService>(IdentityService);

    TenantCreate: TenantCreateModel = 
    {
        CreateTenantCommand:
        {
            Name: ""
        },
        Errors: new Map<string, Array<string>>()
    };
    TenantEdit: TenantEditModel =
    {
        EditTenantCommand:
        {
            Id: "",
            Name: ""
        },
        Errors: new Map<string, Array<string>>()
    };

    // CREATE TENANT
    @Action({ rawError: true })
    public async CreateTenant()
    {
        try
        {
            SharedModule.SET_ISLOADING(true);
            await this._identityService.CreateTenant(this.TenantCreate.CreateTenantCommand);
            SharedModule.SET_ISLOADING(false);
        }
        catch(error)
        {
            SharedModule.SET_ISLOADING(false);
            const errs = parseErrors(error);
            this.SET_TENANTCREATE_ERRORS(errs);
            throw error;
        }
    }
    @Mutation
    public SET_TENANTCREATE_NAME(name: string)
    {
        this.TenantCreate.CreateTenantCommand.Name = name;
    }
    @Mutation
    public SET_TENANTCREATE_ERRORS(errs: Map<string, Array<string>>)
    {
        this.TenantCreate.Errors = errs;
    }

    // EDIT TENANT
    @Action({ rawError: true })
    public async EditTenant()
    {
        try
        {
            SharedModule.SET_ISLOADING(true);
            await this._identityService.EditTenant(this.TenantEdit.EditTenantCommand.Id, this.TenantEdit.EditTenantCommand);
            SharedModule.SET_ISLOADING(false);
        }
        catch(error)
        {
            SharedModule.SET_ISLOADING(false);
            const errs = parseErrors(error);
            this.SET_TENANTEDIT_ERRORS(errs);
            throw error;
        }
    }
    @Action({ rawError: true })
    public async GetTenantToEdit(id: string)
    {
        SharedModule.SET_ISLOADING(true);
        const tenant: TenantModel = await this._identityService.GetTenantToEdit(id);
        this.SET_TENANTEDIT_ID(tenant.Id);
        this.SET_TENANTEDIT_NAME(tenant.Name);
        SharedModule.SET_ISLOADING(false);
    }
    @Mutation
    public SET_TENANTEDIT_ID(id: string)
    {
        this.TenantEdit.EditTenantCommand.Id = id;
    }
    @Mutation
    public SET_TENANTEDIT_NAME(name: string)
    {
        this.TenantEdit.EditTenantCommand.Name = name;
    }
    @Mutation
    public SET_TENANTEDIT_ERRORS(errs: Map<string, Array<string>>)
    {
        this.TenantEdit.Errors = errs;
    }
    
    @Action({ rawError: true })
    public DropTenantCreate()
    {
        this.SET_TENANT_CREATE();
    }
    @Mutation
    public SET_TENANT_CREATE()
    {
        this.TenantCreate.CreateTenantCommand.Name = "";
    }
}

function parseErrors(error: any): Map<string, Array<string>>
    {
        const errs = new Map<string, Array<string>>();
        for (const [key, value] of Object.entries(error.response.data)) {
            const messages = value as Array<string>;
            errs.set(key, messages);
        }
        return errs;
    }

export const TenantStore = getModule(TenantsStore);
