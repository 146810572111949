














import { Component } from "vue-property-decorator";
import { TenantStore } from './store';

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import PgaTenantCreate from './Components/PgaTenantCreate.vue';
import { CreateTenantCommand } from '@/services/iIdentityService';

@Component({
	components:
	{
		PgaTenantCreate
	}
})
export default class TenantsPageCreate extends mixins(SharedMixin)
{

    get TenantCreate() : CreateTenantCommand 
    {
		return TenantStore.TenantCreate.CreateTenantCommand;
	}

    async Create() : Promise<void>
    {
        try 
        {
			await TenantStore.CreateTenant();
			this.$router.go(-1);
		}
        // eslint-disable-next-line no-empty
        catch(error) { }
	}

    undo() : void 
    {
		this.$router.go(-1);
	}

	beforeDestroy() : void
	{
		TenantStore.TenantCreate.Errors = new Map<string, Array<string>>()
		TenantStore.DropTenantCreate();
	}
}
