














import { Component } from "vue-property-decorator";
import { TenantStore } from './store';

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import PgaTenantEdit from './Components/PgaTenantEdit.vue';
import { EditTenantCommand } from '@/services/iIdentityService';

@Component({
	components:
	{
		PgaTenantEdit
	}
})
export default class TenantPageEdit extends mixins(SharedMixin)
{

    get TenantEdit() : EditTenantCommand 
    {
		return TenantStore.TenantEdit.EditTenantCommand;
	}

    async Edit() : Promise<void>
    {
        try 
        {
			await TenantStore.EditTenant();
			this.$router.go(-1);
		}
        // eslint-disable-next-line no-empty
        catch(error) { }
	}

    undo() : void 
    {
		this.$router.go(-1);
    }
    
    async mounted(): Promise<void>
    {
        const id: string = this.$route.params.id;
        await TenantStore.GetTenantToEdit(id);
	}
	
	beforeDestroy() : void
	{
		TenantStore.TenantEdit.Errors = new Map<string, Array<string>>()
	}
}
