























import { Component, Model } from "vue-property-decorator";
import { TenantStore }  from '../store';
import { CreateTenantCommand } from '@/services/iIdentityService';

import { mixins } from "vue-class-component";
import SharedMixin from '@/mixins/SharedMixin';

@Component({})
export default class PgaTenantsCreate extends mixins(SharedMixin) {
	@Model("", { type: Object })
    readonly Model!: CreateTenantCommand;

    get nameTenant() : string {
		return this.Model.Name;
	}
    set nameTenant(value: string)
    {
        TenantStore.SET_TENANTCREATE_NAME(value);
    }

    get Errors(): Map<string, Array<string>>
    {
		return TenantStore.TenantCreate.Errors;
	}

}
